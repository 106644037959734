const HEX_REGEX = /[a-f0-9]{6}/i

export const withOpacity = (color: string, opacity: string | number) => {
  const _opacity = Math.round(
    Math.min(Math.max(Number(opacity) * 0.01, 0), 1) * 255
  )
  const opacityHex = _opacity.toString(16).toUpperCase().padStart(2, '0')
  return `${color}${opacityHex}`
}

export const addOpacityToStrapiText = (
  styleEl: string,
  opacity: string | number
) => {
  if (!styleEl.includes('background-color')) {
    return styleEl
  }

  return styleEl
    .split('background-color:')
    .map((styleEl) => {
      if (styleEl.includes('#')) {
        const [originalHex] = HEX_REGEX.exec(styleEl)
        return styleEl.replace(
          originalHex,
          `${withOpacity(originalHex, opacity)}`
        )
      }
      return styleEl
    })
    .join('background-color:')
}
